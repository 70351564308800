import { FunctionComponent, ChangeEvent } from "react";
import { UseFormRegister } from "react-hook-form";

import { FormNames, RadioValues } from "../types";

interface Props {
  label: string;
  name: FormNames;
  onChange?: (checked: boolean) => void;
  register: UseFormRegister<any>;
  value: RadioValues;
}

const RadioInput: FunctionComponent<Props> = ({
  label,
  name: propName,
  register,
  onChange: changeCallback,
  value,
}) => {
  const { name, onChange, onBlur, ref } = register(propName, {
    required: true,
  });

  const toggleCheck = (event: ChangeEvent<HTMLInputElement>) => {
    changeCallback?.(event.target.checked);
    onChange(event);
  };

  return (
    <label className="RadioButtonContainer">
      <p className="RadioButtonLabel">{label}</p>
      <input
        id={value}
        name={name}
        onBlur={onBlur}
        onChange={toggleCheck}
        ref={ref}
        tabIndex={-1}
        type="radio"
        value={value}
      />
      <span className="RadioButton" tabIndex={0}></span>
    </label>
  );
};

export default RadioInput;
