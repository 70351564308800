import { FunctionComponent } from "react";
import { UseFormRegister } from "react-hook-form";

interface Props {
  id: string;
  index?: number;
  register: UseFormRegister<any>;
  required?: boolean;
}

const TextInput: FunctionComponent<Props> = ({
  id,
  register,
  required,
}) => {
  return (
    <input
      id={id}
      type="text"
      {...register(id, { required })}
    />
  );
};

export default TextInput;
