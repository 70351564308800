import { FunctionComponent, useRef } from "react";
import { UseFormRegister } from "react-hook-form";

import deleteIcon from "../assets/delete.png";
import fileIcon from "../assets/file.png";
import { FormNames } from "../types";

interface Props {
  files: Array<File>;
  register: UseFormRegister<any>;
  setFiles: (files: Array<File>) => void;
}

const FileInput: FunctionComponent<Props> = ({ register, files, setFiles }) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const { ref, onChange, onBlur, name } = register(FormNames.Screenshot);

  // Handle FileList changes.
  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const {
      target: { files: newFiles },
    } = event;
    if (newFiles) {
      setFiles([...files, ...Array.from(newFiles)]);
    } else {
      setFiles([]);
    }
    onChange(event);
  };

  const deletefile = (deleteIndex: number) => {
    const removed = files.filter((_, index) => index !== deleteIndex);
    setFiles(removed);
  };

  const renderHint = () => (
    <p className="FileHintLabel">
      Bitte achte darauf, dass auf dem Screenshot nicht nur der gemeldete
      Kommentar, sondern auch der Gesamtkontext bzw. Verlauf der Kommunikation
      sichtbar ist.
    </p>
  );

  const renderFile = (file: File, index: number) => (
    <div className="FileLabelContainer" key={file.name}>
      <img className="InputDelete" src={fileIcon} alt="file icon" />
      <p className="FileLabel">{file.name}</p>
      <img
        className="FileDelete"
        src={deleteIcon}
        alt="delete"
        onClick={() => deletefile(index)}
      />
    </div>
  );

  return (
    <>
      <label htmlFor={FormNames.Screenshot}>
        (Optional) Hier kannst du einen Screenshot des Vorfalls hochladen.
      </label>
      {renderHint()}
      {files.map(renderFile)}
      <label htmlFor={FormNames.Screenshot} className="FileInput" tabIndex={0}>
        Datei auswählen
        <input
          accept="image/*, .pdf"
          id={FormNames.Screenshot}
          name={name}
          multiple
          type="file"
          ref={(reference) => {
            ref(reference);
            inputRef.current = reference;
          }}
          onChange={handleChange}
          onBlur={onBlur}
        ></input>
      </label>
    </>
  );
};

export default FileInput;
