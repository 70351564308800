export enum FormNames {
  Links = "links",
  Sources = "sources",
  Affected = "affected",
  Name = "name",
  Email = "email",
  Comments = "comment",
  Screenshot = "screenshot",
  Extra = "extra",
}

export type FormValues = {
  [FormNames.Links]: Array<any>;
  [FormNames.Sources]: Array<any>;
  [FormNames.Affected]: boolean;
  [FormNames.Name]: string;
  [FormNames.Email]: string;
  [FormNames.Comments]: string;
  [FormNames.Screenshot]: Array<File>;
  [FormNames.Extra]: string;
};

export enum RadioValues {
  Yes = "yes",
  No = "no",
}
