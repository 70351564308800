import { FunctionComponent } from "react";
import {
  Control,
  FieldError,
  useFieldArray,
  UseFormRegister,
} from "react-hook-form";

import { FormNames, FormValues } from "../types";
import deleteIcon from "../assets/delete.png";
import ErrorLabel from "./ErrorLabel";

interface Props {
  id: FormNames.Links | FormNames.Sources;
  error?: FieldError;
  title: string;
  register: UseFormRegister<any>;
  control: Control<FormValues>;
}

const ArrayInput: FunctionComponent<Props> = ({
  id,
  title,
  control,
  error,
  register,
}) => {
  const { fields, remove, append } = useFieldArray({
    control,
    name: id,
  });

  const deleteField = (index: number) => {
    remove(index);
  };

  const appendField = () => {
    append({ value: "" });
  };

  const validateLink = (link: string) =>
    /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=+$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=+$,\w]+@)[A-Za-z0-9.-]+)((?:\/[+~%/.\w-_]*)?\??(?:[-+=&;%@.\w_]*)#?(?:[\w]*))?)/.test(
      link
    );

  return (
    <section>
      <label className={error ? "ErrorLabel" : ""} htmlFor={id}>
        {title}
      </label>
      {fields.map((field, index) => {
        const firstField = index === 0;
        return (
          <div className="InputContainer" key={index}>
            <input
              key={field.id}
              type="text"
              defaultValue={field.value}
              {...register(`${id}.${index}.value` as const, {
                required: true,
                validate: {
                  valid: validateLink,
                },
              })}
            />
            {!firstField ? (
              <img
                className="InputDelete"
                src={deleteIcon}
                onClick={() => deleteField(index)}
                alt="delete"
              />
            ) : (
              <div className="InputSpacer" />
            )}
          </div>
        );
      })}
      {error?.type && <ErrorLabel type={error?.type} />}
      <button type="button" onClick={appendField}>
        Weiteren hinzufügen
      </button>
    </section>
  );
};

export default ArrayInput;
