import { useEffect, useRef, MutableRefObject, useState } from "react";
import { useForm } from "react-hook-form";
import { WidgetInstance } from "friendly-challenge";

import "./App.css";
import { sendReport } from "./api";
import ArrayInput from "./components/ArrayInput";
import ErrorLabel from "./components/ErrorLabel";
import FileInput from "./components/FileInput";
import RadioInput from "./components/RadioInput";
import TextInput from "./components/TextInput";
import serverError from "./assets/server-warning.png";
import { FormNames, FormValues, RadioValues } from "./types";

declare global {
  interface Window {
    grecaptcha: any;
  }
}

const defaultValues = {
  [FormNames.Links]: [{ value: "" }],
  [FormNames.Sources]: [{ value: "" }],
};

function App() {
  const siteKey = process.env.REACT_APP_FRIENDLY_SITE_KEY as string;
  const container = useRef() as MutableRefObject<HTMLInputElement>;
  const captcha = useRef<WidgetInstance>();
  const [files, setFiles] = useState<Array<File>>([]);
  const [affected, setAffected] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(false);
  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues,
    mode: "onSubmit",
    reValidateMode: "onSubmit",
  });

  // Initialise Captcha.
  useEffect(() => {
    if (!captcha.current && container.current) {
      captcha.current = new WidgetInstance(container.current, {
        startMode: "focus",
        language: "de",
      });
    }
    return () => {
      if (captcha.current !== undefined) captcha.current.reset();
    };
  }, [container]);

  const onSubmit = (formData: FormValues) => {
    sendReport({
      ...formData,
      [FormNames.Screenshot]: files,
      affected,
    })
      .then(() => {
        setSubmitted(true);
      })
      .catch((e) => {
        console.log(e);
        setError(true);
      });
  };

  const renderContent = () => {
    if (submitted) {
      return <p>Eingereicht</p>;
    }
    if (error) {
      return renderError();
    }
    return renderForm();
  };

  const renderError = () => (
    <div className="ServerContainer">
      <img src={serverError} alt="server error" className="ServerImage" />
      <p className="ServerLabel">
        Es ist ein Serverfehler aufgetreten. Bitte versuchen Sie es später noch
        einmal.
      </p>
    </div>
  );

  const renderForm = () => (
    <form onSubmit={handleSubmit(onSubmit)}>
      <section>
        {/* Links */}
        <ArrayInput
          id={FormNames.Links}
          control={control}
          error={errors.links?.[0]?.value}
          register={register}
          title="Link zum Kommentar oder Inhalt *"
        />
      </section>

      {/* Source links */}
      <section>
        <ArrayInput
          id={FormNames.Sources}
          control={control}
          error={errors.sources?.[0]?.value}
          register={register}
          title="Link zum Ausgangs-Post *"
        />
      </section>

      {/* User is affected */}
      <section className="RadioButtonSection">
        <label htmlFor={FormNames.Affected} className="RadioButtonHeader">
          Bist du selbst von dem Kommentar betroffen? *
        </label>
        <RadioInput
          label="Ja"
          register={register}
          value={RadioValues.Yes}
          name={FormNames.Affected}
          onChange={() => setAffected(true)}
        />
        <RadioInput
          label="Nein"
          register={register}
          value={RadioValues.No}
          name={FormNames.Affected}
          onChange={() => setAffected(false)}
        />
        <ErrorLabel type={errors?.affected?.type} />
      </section>

      {/* User's name */}
      <section>
        <label htmlFor={FormNames.Name}>
          {`Dein Name oder Pseudonym (Angabe nur erforderlich, wenn du persönlich von dem Vorfall betroffen bist) ${
            affected ? "*" : ""
          }`}
        </label>
        <br />
        <TextInput
          id={FormNames.Name}
          register={register}
          required={affected}
        />
        {affected && <ErrorLabel type={errors?.name?.type} />}
      </section>

      {/* User's email */}
      <section>
        <label>
          {`Deine E-Mail-Adresse (Angabe nur erforderlich, wenn du persönlich von dem Vorfall betroffen bist) ${
            affected ? "*" : ""
          }`}
        </label>
        <br />
        <TextInput
          id={FormNames.Email}
          register={register}
          required={affected}
        />
        {affected && <ErrorLabel type={errors?.email?.type} />}
      </section>

      {/* Comment text */}
      <section>
        <label>
          (Optional) Hier kannst du den Text des Kommentars einfügen
          (Copy-and-paste)
        </label>
        <br />
        <textarea
          id={FormNames.Comments}
          cols={35}
          rows={5}
          {...register(FormNames.Comments)}
        />
      </section>

      {/* Screenshot */}
      <section>
        <FileInput register={register} files={files} setFiles={setFiles} />
      </section>

      {/* Extra */}
      <section>
        <label htmlFor={FormNames.Extra}>
          (Optional) Möchtest du noch weitere Angaben zum Vorfall machen?
        </label>
        <br />
        <textarea
          id={FormNames.Extra}
          cols={35}
          rows={5}
          {...register(FormNames.Extra)}
        />
      </section>

      <div className="Challenge" ref={container} data-sitekey={siteKey} />

      <input className="SubmitButton" type="submit" value="Absenden" />
    </form>
  );

  return (
    <div className="App">
      <div className="AppContent">
        <h1 className="Headline">Meldeformular</h1>
        {renderContent()}
      </div>
    </div>
  );
}

export default App;
