import { FormValues } from "../types";

// Converts a single File to Base64.
const toBase64 = (file: File): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      if (reader?.result) {
        resolve(reader.result.toString());
      }
    };
    reader.onerror = (error) => reject(error);
  });

// Iterate FileList, convert and prepare into array.
const prepareFiles = async (fileList: Array<File>) => {
  const files = [] as Array<any>;
  for (const file of fileList) {
    const content = await toBase64(file);
    const fileSplit = file.name.split(".");
    const fileType = fileSplit.pop();
    const fileName = fileSplit.pop();
    files.push({
      content,
      contentType: `image/${fileType === "jpg" ? "jpeg" : fileType}`,
      fileName,
    });
  }
  return files;
};

export const sendReport = async (data: FormValues) => {
  const endpoint = process.env.REACT_APP_WEB_SERVICE_URL as string;
  const files = await prepareFiles(data.screenshot);
  const links = data?.links.map((link) => link.value);
  const sources = data?.sources.map((source) => source.value);
  const reportData = {
    SuppliedPseudonym: data?.name,
    SuppliedEmail: data?.email,
    SuppliedAffectedPerson: data.affected ? "Selbst" : "Dritte Person",
    SuppliedPostsUrls: links.concat(sources).join(";"),
    SuppliedContent: data?.comment,
    SuppliedDescription: data?.extra,
    Files: files ?? [],
  };
  const res = await fetch(`${endpoint}/submitReport`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      reportData,
    }),
  });
  const json = await res.json();
  return json;
};
