import { FunctionComponent } from "react";

interface Props {
  type?: string;
}

const ErrorLabel: FunctionComponent<Props> = ({ type }) => {
  const getErrorText = () => {
    switch (type) {
      case "required":
        return "Dies ist ein Pflichtfeld.";
      case "valid":
      default:
        return "Ungültig";
    }
  };

  if (!type) return null;

  return <p className="ErrorLabel">{getErrorText()}</p>;
};

export default ErrorLabel;
